import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >


<path d="M4960 10669 c-437 -32 -1029 -161 -1395 -304 -33 -13 -107 -41 -165
-63 -58 -22 -118 -48 -135 -56 -16 -9 -38 -16 -47 -16 -10 0 -18 -4 -18 -10 0
-5 -5 -10 -11 -10 -37 0 -514 -261 -704 -385 -49 -33 -97 -64 -107 -70 -29
-18 -177 -126 -190 -138 -7 -7 -33 -28 -58 -47 -25 -19 -47 -37 -50 -40 -3 -3
-21 -17 -40 -31 -19 -15 -51 -42 -71 -60 -20 -19 -54 -47 -75 -64 -51 -40
-394 -383 -466 -465 -31 -36 -66 -76 -78 -90 -12 -14 -34 -41 -49 -60 -14 -19
-32 -41 -39 -48 -7 -8 -25 -30 -40 -50 -15 -21 -33 -43 -40 -50 -6 -8 -42 -57
-79 -110 -37 -53 -70 -99 -73 -102 -14 -12 -201 -314 -257 -415 -145 -264
-280 -562 -348 -770 -15 -44 -31 -89 -36 -100 -10 -22 -28 -78 -63 -200 -85
-293 -126 -488 -175 -835 -48 -342 -49 -1025 -1 -1355 47 -321 81 -496 132
-685 50 -183 97 -339 108 -355 4 -5 10 -24 13 -41 7 -39 126 -338 179 -452 62
-135 157 -319 205 -400 23 -41 43 -76 43 -78 0 -6 89 -149 133 -214 133 -198
206 -301 227 -320 3 -3 17 -21 32 -42 15 -20 33 -42 40 -50 7 -7 25 -29 39
-48 15 -19 35 -45 45 -56 11 -12 39 -46 64 -75 128 -152 387 -406 555 -544 27
-22 59 -50 72 -62 13 -13 28 -23 33 -23 4 0 10 -4 12 -8 2 -4 23 -23 48 -42
24 -19 56 -45 70 -57 13 -13 28 -23 32 -23 3 0 13 -6 20 -13 46 -46 426 -288
603 -384 138 -75 394 -198 490 -234 27 -11 70 -29 94 -40 141 -65 622 -207
899 -265 60 -12 255 -45 294 -48 24 -3 97 -11 163 -20 323 -41 922 -39 1245 4
156 21 455 70 485 80 17 5 73 19 125 30 172 38 586 168 673 211 9 5 46 20 82
34 36 13 80 31 98 39 17 8 59 26 92 41 94 42 357 176 387 196 14 11 29 19 32
19 22 0 359 216 519 333 95 70 110 81 232 182 221 183 373 326 515 484 41 46
80 89 85 95 6 6 50 58 99 116 48 58 98 117 111 132 12 15 25 33 29 40 3 7 22
33 41 58 19 25 54 74 79 110 71 104 100 145 109 148 4 2 7 8 7 13 0 5 12 28
27 52 150 236 345 616 436 852 52 136 90 242 117 330 17 55 42 134 54 175 61
195 136 560 161 780 9 74 20 173 26 220 13 114 19 650 9 825 -7 134 -13 187
-45 425 -30 223 -110 592 -165 760 -10 30 -28 89 -40 130 -13 41 -27 79 -32
85 -4 5 -8 17 -8 27 0 9 -6 32 -14 50 -8 18 -21 51 -29 73 -31 80 -90 221
-101 240 -6 11 -22 47 -35 80 -28 69 -155 320 -206 406 -19 33 -51 87 -72 122
-52 89 -137 219 -205 314 -32 44 -58 86 -58 91 0 6 -4 12 -9 14 -5 2 -40 44
-79 93 -39 50 -84 105 -101 123 -17 18 -31 35 -31 38 0 2 -8 12 -17 22 -10 10
-38 43 -63 73 -93 111 -509 515 -544 528 -6 2 -16 12 -23 21 -14 20 -233 189
-348 269 -227 157 -433 280 -665 396 -277 139 -528 240 -825 330 -132 41 -149
46 -280 76 -317 74 -546 111 -820 134 -187 15 -706 18 -895 4z m718 -29 c355
-19 714 -74 1022 -157 52 -14 120 -31 150 -38 63 -16 380 -120 425 -140 17 -8
82 -35 145 -60 296 -120 751 -369 990 -542 82 -59 224 -166 230 -172 3 -4 21
-18 42 -33 20 -15 42 -33 50 -40 7 -7 42 -38 78 -68 212 -179 600 -581 699
-723 8 -12 26 -33 38 -47 13 -14 23 -28 23 -32 0 -3 6 -13 13 -20 60 -62 297
-422 401 -608 65 -115 214 -424 257 -531 22 -57 47 -117 54 -134 36 -84 109
-300 145 -430 52 -183 60 -216 89 -350 200 -937 150 -1877 -148 -2775 -127
-380 -290 -738 -473 -1033 -28 -45 -56 -91 -62 -102 -26 -48 -129 -200 -137
-203 -5 -2 -9 -10 -9 -18 0 -8 -4 -14 -10 -14 -5 0 -10 -5 -10 -10 0 -6 -5
-16 -10 -23 -6 -7 -51 -66 -102 -132 -82 -108 -123 -158 -223 -275 -236 -273
-623 -621 -920 -826 -55 -37 -105 -73 -112 -80 -6 -6 -25 -17 -42 -24 -17 -7
-31 -15 -31 -19 0 -4 -17 -15 -37 -26 -21 -11 -45 -26 -55 -34 -9 -8 -45 -30
-80 -48 -35 -19 -81 -44 -103 -56 -22 -12 -42 -25 -45 -28 -7 -8 -313 -160
-380 -188 -30 -13 -73 -32 -95 -42 -185 -85 -522 -195 -805 -263 -450 -109
-785 -149 -1240 -149 -411 0 -723 32 -1110 115 -94 20 -118 26 -300 74 -117
30 -360 109 -417 135 -24 10 -46 19 -50 19 -43 0 -686 303 -723 340 -3 3 -25
16 -50 29 -70 38 -279 173 -405 262 -371 261 -774 644 -1071 1019 -32 41 -65
81 -71 90 -27 33 -212 306 -275 405 -198 313 -386 716 -517 1110 -311 934
-351 1946 -115 2904 133 540 373 1108 643 1526 25 39 54 86 64 105 11 19 22
37 26 40 3 3 11 12 16 22 24 39 134 190 203 278 279 353 607 679 954 948 120
93 382 272 398 272 5 0 10 5 10 10 0 6 4 10 9 10 5 0 49 24 98 54 412 247 878
442 1338 560 562 145 1057 195 1623 166z"/>
<path d="M3571 9288 c-18 -57 -36 -110 -40 -118 -6 -12 -34 -16 -130 -18
l-121 -3 0 -33 c0 -27 7 -39 38 -62 20 -16 42 -33 49 -39 6 -5 28 -21 47 -35
19 -14 37 -30 40 -36 2 -6 -5 -38 -15 -70 -21 -62 -39 -170 -31 -179 18 -17
66 2 145 56 l88 61 50 -33 c28 -18 56 -37 62 -43 54 -51 119 -60 125 -17 4 24
-35 177 -50 197 -12 16 -2 37 25 53 12 8 50 36 85 64 53 44 62 55 62 83 l0 33
-122 3 -123 3 -40 118 -40 117 -35 0 -36 0 -33 -102z m79 70 c0 -2 17 -52 39
-113 l38 -110 44 -7 c24 -3 82 -7 128 -7 99 -1 99 -1 -25 -93 -50 -37 -84 -69
-84 -80 0 -9 11 -50 24 -90 13 -40 27 -88 31 -107 9 -43 4 -41 -110 40 l-88
62 -33 -18 c-18 -10 -42 -26 -53 -36 -11 -11 -23 -19 -26 -19 -3 0 -15 -8 -26
-18 -23 -23 -67 -47 -73 -41 -2 2 11 56 29 119 l33 115 -22 20 c-11 11 -37 31
-56 45 -19 13 -37 27 -40 30 -3 3 -20 16 -37 29 -18 12 -33 27 -33 32 0 5 48
9 108 9 72 0 113 4 124 13 8 6 28 48 43 92 42 124 47 135 56 135 5 0 9 -1 9
-2z"/>
<path d="M4447 9383 c-2 -5 -19 -53 -37 -108 -18 -55 -37 -106 -43 -112 -6 -9
-44 -13 -124 -13 -92 0 -115 -3 -119 -15 -13 -32 8 -61 75 -109 112 -78 109
-70 78 -179 -15 -50 -28 -105 -29 -122 -3 -27 0 -30 28 -33 25 -2 50 10 119
57 49 33 93 57 99 54 14 -9 104 -68 131 -87 11 -7 37 -16 58 -19 l37 -6 0 39
c0 22 -11 74 -25 116 -14 42 -25 82 -25 90 0 7 38 41 84 76 81 60 96 82 88
121 -3 15 -16 17 -96 17 -144 0 -148 2 -169 68 -10 31 -28 83 -40 115 -20 53
-24 57 -54 57 -17 0 -34 -3 -36 -7z m82 -125 c18 -57 39 -109 45 -116 11 -11
76 -18 214 -21 17 -1 32 -4 32 -8 0 -5 -22 -24 -48 -43 -48 -36 -65 -50 -116
-92 l-29 -24 31 -95 c46 -144 45 -139 30 -139 -7 0 -53 30 -103 65 -49 36 -94
65 -99 65 -4 0 -48 -30 -98 -66 -49 -36 -95 -64 -102 -62 -10 2 -4 30 20 108
18 58 33 112 33 120 0 8 -37 44 -84 79 -46 35 -89 69 -95 76 -8 10 14 13 110
17 65 2 122 7 125 10 4 3 19 44 35 90 42 122 49 138 58 138 5 0 23 -46 41
-102z"/>
<path d="M5306 9382 c-3 -5 -19 -53 -37 -108 -17 -54 -36 -105 -41 -111 -7 -9
-45 -13 -125 -13 -92 0 -115 -3 -119 -15 -13 -33 9 -62 91 -124 47 -35 85 -66
85 -70 -1 -14 -26 -97 -31 -106 -12 -20 -31 -116 -25 -130 7 -18 53 -19 83 -2
12 6 52 34 89 60 l67 48 56 -37 c31 -21 69 -49 85 -62 40 -32 90 -28 94 9 3
24 -38 184 -52 208 -7 10 36 49 117 106 56 39 75 70 63 100 -4 11 -23 15 -78
15 -100 0 -178 11 -178 24 0 6 -17 57 -37 114 -37 101 -38 102 -70 102 -17 0
-34 -4 -37 -8z m59 -59 c9 -21 26 -67 37 -103 11 -36 25 -71 31 -77 6 -9 45
-13 128 -13 89 0 119 -3 119 -12 0 -7 -26 -32 -58 -55 -31 -24 -75 -58 -96
-76 l-39 -33 35 -112 c20 -62 32 -115 27 -118 -7 -4 -92 47 -109 65 -3 3 -12
11 -21 16 -9 6 -28 19 -43 29 -27 19 -28 19 -59 1 -18 -11 -54 -36 -81 -56
-56 -42 -94 -64 -100 -58 -4 4 9 53 51 186 10 29 15 54 12 56 -2 2 -42 32 -89
67 -47 35 -89 67 -95 72 -16 14 16 29 60 27 22 0 72 0 110 0 81 1 74 -7 120
139 17 51 33 92 37 92 4 0 15 -17 23 -37z"/>
<path d="M6130 9277 l-36 -112 -39 -7 c-22 -4 -79 -7 -127 -7 l-88 -1 0 -32
c0 -29 10 -40 72 -88 39 -30 80 -62 90 -70 20 -16 20 -16 0 -75 -29 -90 -45
-163 -38 -180 11 -31 60 -16 149 46 l88 61 27 -18 c15 -10 54 -37 86 -61 45
-34 67 -43 90 -41 42 4 44 31 12 146 -15 50 -26 97 -26 104 0 7 22 28 48 46
96 64 133 102 129 130 -4 27 -4 27 -117 34 -63 4 -119 9 -126 12 -6 2 -28 54
-49 115 l-37 111 -36 0 -37 0 -35 -113z m100 36 c7 -27 16 -52 20 -58 4 -5 15
-36 24 -67 l17 -58 107 -1 c155 -1 160 -5 82 -65 -35 -27 -79 -63 -98 -79
l-34 -31 31 -106 c33 -113 36 -128 23 -128 -5 0 -50 29 -100 65 -50 36 -94 65
-98 65 -10 0 -88 -53 -92 -62 -2 -5 -9 -8 -15 -8 -7 -1 -28 -14 -47 -30 -37
-33 -66 -40 -56 -15 3 9 13 41 22 73 9 31 23 77 31 102 14 45 14 45 -15 70
-35 30 -52 44 -114 90 -26 19 -46 40 -44 45 1 6 53 11 119 13 l117 3 19 37
c10 20 29 72 42 115 13 42 29 77 35 77 6 0 17 -21 24 -47z"/>
<path d="M7025 9378 c-75 -222 -71 -213 -101 -219 -16 -4 -73 -7 -126 -8 l-98
-1 0 -34 c0 -31 7 -40 77 -93 42 -32 83 -62 90 -68 10 -8 7 -30 -17 -108 -38
-125 -37 -144 8 -149 27 -3 47 5 109 49 82 57 108 63 139 35 50 -46 119 -85
153 -86 32 -1 36 2 39 26 3 23 -39 183 -55 210 -2 4 6 15 17 25 12 10 36 30
53 45 18 15 35 28 39 28 3 0 20 12 37 26 24 19 31 34 31 60 l0 34 -102 0 c-57
0 -112 3 -123 6 -16 4 -29 31 -57 117 l-37 112 -35 3 c-23 2 -38 -2 -41 -10z
m89 -132 l37 -114 124 -7 c69 -4 125 -10 125 -14 -1 -3 -43 -37 -95 -76 -52
-38 -94 -77 -94 -85 0 -8 15 -62 33 -120 25 -79 30 -106 20 -108 -8 -2 -19 2
-26 7 -36 31 -170 121 -179 121 -6 0 -36 -19 -67 -43 -69 -52 -131 -91 -138
-85 -4 4 15 73 52 187 17 53 18 51 -92 133 -46 34 -84 65 -84 69 0 9 91 19
171 19 41 0 70 5 76 13 13 15 52 119 66 175 6 23 16 42 22 42 6 0 28 -51 49
-114z"/>
<path d="M1350 8421 c0 -44 -6 -41 130 -56 95 -11 211 -51 281 -99 163 -110
238 -259 259 -519 13 -150 12 -2982 0 -3111 -26 -266 -119 -426 -311 -534 -52
-29 -133 -50 -244 -63 -55 -7 -105 -16 -111 -20 -18 -14 -4 -67 20 -73 28 -8
1445 -7 1474 0 17 5 22 14 22 40 0 40 4 38 -122 53 -228 26 -375 117 -467 291
-38 71 -54 123 -78 255 -17 94 -18 196 -18 1642 0 1093 3 1545 11 1553 6 6 17
8 25 5 12 -6 108 -123 314 -385 37 -47 74 -94 83 -105 9 -11 33 -40 52 -65 36
-46 67 -84 85 -104 6 -6 33 -40 60 -76 27 -36 52 -67 55 -70 3 -3 21 -25 40
-50 19 -25 40 -52 47 -60 7 -8 42 -53 79 -100 37 -47 78 -99 93 -116 42 -49
64 -77 93 -116 15 -20 33 -42 40 -50 7 -7 33 -40 58 -73 25 -33 51 -66 57 -75
7 -8 45 -55 84 -105 39 -49 81 -101 92 -115 12 -14 37 -45 56 -70 19 -25 39
-50 45 -56 6 -6 22 -26 36 -44 14 -18 90 -115 170 -215 80 -100 156 -197 170
-215 14 -18 30 -38 35 -44 6 -6 42 -51 80 -101 39 -49 77 -97 85 -105 8 -9 25
-29 37 -46 27 -36 21 -27 253 -319 105 -132 198 -249 207 -259 9 -11 31 -39
49 -63 18 -24 58 -74 88 -111 61 -76 298 -374 336 -424 14 -18 42 -50 62 -71
l37 -38 241 3 c201 3 242 5 250 18 11 17 14 2477 4 2529 l-6 31 -279 0 c-308
0 -290 4 -265 -66 35 -100 41 -209 41 -767 0 -524 -1 -549 -19 -562 -17 -13
-24 -7 -84 68 -36 45 -68 84 -72 87 -4 3 -11 12 -16 21 -9 17 -39 55 -114 148
-25 31 -52 66 -60 77 -8 12 -43 57 -78 100 -34 44 -79 101 -100 127 -20 27
-47 61 -60 75 -12 15 -26 32 -30 37 -44 62 -101 135 -119 155 -13 14 -23 28
-23 31 0 3 -12 19 -26 35 -14 16 -46 56 -72 89 -58 75 -57 74 -88 110 -13 17
-34 45 -45 63 -11 17 -24 32 -28 32 -4 0 -15 13 -24 30 -10 16 -23 35 -30 42
-7 7 -36 42 -63 78 -162 209 -278 358 -292 375 -10 11 -33 40 -52 65 -19 25
-38 50 -43 55 -4 6 -40 51 -80 102 -39 50 -98 124 -129 165 -31 40 -94 121
-140 179 -46 58 -103 132 -128 163 -25 32 -83 107 -130 166 -47 59 -112 143
-145 185 -33 43 -65 83 -70 89 -6 6 -33 40 -60 76 -27 36 -52 67 -55 70 -3 3
-31 39 -62 80 -31 41 -66 86 -78 100 -12 14 -26 32 -30 40 -7 13 -92 15 -649
18 l-641 2 0 -39z"/>
<path d="M4012 8393 l3 -38 200 -7 c393 -12 590 -35 717 -84 61 -23 69 -30 90
-72 l23 -47 5 -665 5 -665 444 -3 c303 -1 448 1 455 8 8 8 13 237 16 708 4
622 7 700 21 727 47 84 99 95 479 95 340 0 486 -14 651 -64 341 -103 508 -331
559 -764 10 -83 21 -102 60 -102 14 0 51 -4 81 -10 30 -5 99 -17 153 -26 181
-30 314 -84 525 -213 59 -36 88 -48 95 -41 16 16 20 215 6 295 -14 79 -57 195
-95 255 -14 22 -25 42 -25 45 0 3 -5 11 -10 18 -6 7 -39 48 -74 93 -123 155
-312 303 -513 404 -217 107 -390 151 -713 179 -59 6 -780 10 -1633 10 l-1528
1 3 -37z"/>
<path d="M7335 7324 c-66 -10 -174 -36 -250 -60 -79 -25 -257 -110 -292 -138
-10 -8 -47 -36 -83 -63 -121 -88 -300 -282 -356 -383 -10 -19 -22 -37 -25 -40
-11 -10 -68 -137 -90 -203 -34 -97 -32 -97 180 -96 91 1 177 4 191 8 25 7 25
9 31 136 7 157 18 204 71 313 119 243 418 412 785 444 252 22 472 -20 698
-134 211 -107 459 -341 627 -593 113 -170 218 -436 263 -665 19 -95 36 -122
74 -118 l26 3 3 784 c1 489 -1 788 -7 794 -10 10 -41 -5 -72 -35 -17 -15 -89
-79 -185 -165 -38 -34 -91 -82 -119 -107 -27 -26 -62 -52 -77 -58 -15 -7 -24
-16 -22 -20 3 -4 -1 -8 -10 -8 -8 0 -16 4 -18 10 -1 5 -37 32 -78 60 -323 218
-395 251 -655 305 -114 23 -158 27 -355 30 -124 2 -238 1 -255 -1z"/>
<path d="M7696 7128 c-3 -13 -9 -61 -15 -108 -11 -94 -51 -241 -83 -308 -60
-125 -185 -270 -275 -319 -110 -61 -212 -96 -344 -119 -74 -13 -180 -18 -496
-21 l-403 -5 0 -39 0 -39 368 -1 c517 0 608 -8 787 -67 116 -38 253 -116 330
-189 43 -40 127 -142 155 -189 13 -21 26 -41 30 -44 3 -3 14 -22 25 -42 10
-21 31 -42 45 -48 14 -5 133 -37 265 -70 269 -68 403 -106 551 -156 116 -39
144 -42 144 -16 -1 87 -233 380 -375 475 -22 15 -57 39 -78 53 -137 91 -430
211 -632 258 -38 9 -79 19 -90 22 -11 3 -60 14 -110 24 -49 10 -80 18 -68 19
14 1 21 6 17 14 -6 15 18 24 179 62 220 53 495 153 537 195 3 3 21 14 40 24
53 29 162 111 190 143 61 69 90 113 89 134 0 27 -67 85 -198 175 -142 96 -275
151 -446 185 -126 24 -134 24 -139 -3z"/>
<path d="M6163 6054 c-12 -31 6 -299 26 -389 82 -378 281 -625 647 -802 214
-103 538 -220 814 -292 207 -54 445 -130 675 -215 91 -34 312 -137 360 -168
116 -75 151 -103 220 -172 80 -81 149 -183 180 -267 9 -24 21 -53 26 -64 14
-34 39 -196 39 -261 0 -34 -7 -101 -15 -150 -16 -89 -61 -226 -77 -232 -4 -2
-8 -10 -8 -17 0 -21 -89 -134 -162 -207 -61 -60 -192 -154 -258 -186 -70 -32
-183 -70 -245 -82 -76 -13 -399 -8 -510 8 -78 12 -269 64 -320 87 -22 10 -60
27 -85 38 -104 48 -224 132 -313 220 -51 51 -102 103 -112 117 -90 115 -165
228 -165 248 0 6 -4 12 -8 12 -4 0 -39 65 -77 145 -81 169 -54 154 -275 152
l-145 -2 0 -549 c0 -409 3 -550 12 -553 6 -2 41 33 78 79 36 46 73 91 81 101
8 9 14 21 14 26 0 5 3 11 8 13 4 2 41 48 82 103 41 55 80 107 87 115 6 8 27
35 45 60 44 59 67 61 110 14 103 -115 270 -264 296 -264 6 0 12 -4 14 -9 7
-17 200 -113 296 -146 210 -72 472 -115 703 -115 159 0 394 45 547 104 108 41
264 125 292 156 3 3 30 24 60 47 75 57 259 245 260 266 0 4 8 17 18 28 29 30
128 234 156 319 30 91 52 189 66 293 13 101 13 426 -1 542 -25 222 -92 411
-203 580 -44 68 -197 222 -241 245 -17 8 -32 18 -35 22 -6 8 -207 108 -218
108 -4 0 -19 6 -33 14 -14 7 -68 28 -120 46 -52 18 -108 39 -126 46 -17 8 -36
14 -42 14 -6 0 -46 11 -88 25 -43 14 -91 29 -108 35 -30 9 -90 25 -235 60 -97
24 -280 72 -400 105 -52 15 -111 31 -131 36 -39 9 -171 54 -204 69 -11 5 -59
25 -106 44 -96 39 -203 94 -267 137 -24 16 -46 29 -49 29 -11 0 -109 86 -148
130 -22 25 -56 62 -76 83 l-37 37 -281 0 c-250 0 -282 -2 -288 -16z"/>
<path d="M6382 4488 c-17 -17 -18 -598 0 -616 18 -18 217 -16 224 2 3 8 -4 48
-15 88 -32 109 -79 284 -96 358 -24 98 -36 143 -44 160 -10 19 -52 25 -69 8z"/>
<path d="M7875 4392 c-6 -4 -15 -18 -21 -32 -98 -220 -160 -310 -289 -417 -61
-49 -193 -120 -244 -129 -20 -4 -40 -10 -46 -14 -5 -4 -32 -10 -60 -14 -27 -4
-95 -14 -150 -21 -175 -25 -744 -12 -900 21 -38 7 -74 14 -78 14 -4 0 -7 -26
-5 -57 l3 -58 725 -3 c735 -3 774 -2 960 34 248 49 505 182 704 366 41 38 57
60 53 71 -12 32 -307 146 -627 242 -8 3 -20 1 -25 -3z"/>
<path d="M1342 3538 c-17 -17 -15 -53 3 -68 23 -19 22 -342 -1 -360 -9 -8 -14
-25 -12 -44 l3 -31 74 -3 c78 -3 91 3 91 44 0 12 -7 27 -15 34 -11 9 -15 34
-15 99 l1 86 27 -31 c35 -40 54 -64 111 -137 25 -33 53 -68 61 -79 14 -17 28
-18 155 -16 l140 3 3 27 c2 15 -1 35 -7 45 -18 34 4 53 63 53 47 0 54 -3 59
-22 4 -12 2 -28 -4 -34 -6 -7 -9 -25 -7 -41 l3 -28 291 0 c235 0 294 3 308 14
14 12 18 12 29 -3 10 -14 26 -16 92 -14 l80 3 3 34 c2 18 -1 38 -7 44 -7 7 -7
16 0 29 14 26 104 26 118 0 6 -11 6 -24 0 -35 -6 -10 -9 -30 -7 -45 l3 -27
250 0 250 0 3 31 c2 19 -3 36 -12 44 -13 10 -16 40 -16 171 0 91 4 159 9 159
13 0 51 -49 51 -65 0 -14 22 -25 50 -25 15 0 18 -12 22 -89 4 -77 8 -96 34
-137 35 -58 101 -95 177 -102 90 -7 141 15 200 87 22 28 38 141 36 256 -2 76
1 90 15 93 14 3 16 -14 16 -166 0 -139 -3 -172 -15 -182 -19 -16 -19 -54 -1
-69 9 -8 73 -11 207 -9 l193 3 8 39 c5 22 10 41 12 43 1 2 20 -13 40 -32 54
-51 131 -71 216 -57 56 10 70 16 103 51 43 47 47 49 47 20 0 -15 14 -26 53
-44 68 -30 140 -39 213 -26 48 9 66 18 95 49 20 21 38 44 41 52 3 11 19 0 54
-36 40 -41 60 -54 103 -64 111 -26 211 11 255 95 18 33 22 64 26 185 4 120 8
145 20 145 13 0 15 -26 18 -167 1 -106 -1 -168 -7 -168 -20 0 -31 -37 -21 -65
l10 -27 178 4 c191 3 205 7 259 68 47 54 38 149 -19 193 -30 24 -30 25 -14 55
21 41 20 80 -4 119 -43 71 -108 85 -359 81 l-185 -3 -7 -27 c-4 -17 -2 -34 8
-48 21 -30 19 -274 -3 -307 -19 -30 -73 -31 -100 -2 -16 18 -19 40 -22 160 -3
119 -1 140 12 145 22 8 22 70 0 78 -9 3 -60 6 -114 6 -77 0 -102 -4 -114 -16
-15 -14 -20 -14 -63 0 -26 9 -74 16 -109 16 -62 0 -134 -22 -134 -40 0 -20
-21 -9 -30 15 -9 23 -15 25 -69 25 -33 0 -66 -3 -75 -6 -21 -8 -21 -67 -1 -84
12 -10 15 -38 15 -130 0 -131 -11 -177 -46 -190 -26 -10 -63 0 -81 22 -8 9
-13 64 -14 151 -1 120 1 141 17 158 14 15 16 25 8 47 -9 27 -10 27 -119 30
-95 2 -111 0 -122 -15 -18 -23 -16 -60 2 -67 12 -4 15 -27 15 -121 0 -113 0
-115 -23 -115 -16 0 -27 -11 -42 -42 -16 -32 -28 -44 -54 -51 -60 -15 -61 -14
-61 159 0 134 3 160 17 176 13 14 15 25 7 46 l-9 27 -179 3 c-123 2 -183 -1
-192 -9 -19 -15 -18 -68 1 -75 12 -5 15 -30 15 -136 0 -160 -10 -188 -70 -188
-62 0 -70 20 -70 179 0 112 3 138 17 153 13 14 15 25 7 46 l-9 27 -310 3
c-171 1 -320 0 -331 -3 -19 -4 -24 -16 -34 -91 -14 -97 -10 -107 41 -102 26 2
35 8 37 24 2 12 14 32 27 44 34 32 36 21 33 -155 -3 -146 -4 -150 -25 -153
-38 -6 -47 11 -184 348 -17 41 -36 78 -42 82 -14 9 -113 10 -144 2 -18 -5 -23
-14 -23 -38 0 -18 4 -36 10 -42 6 -6 -5 -48 -33 -122 -24 -61 -46 -115 -50
-118 -4 -4 -10 2 -13 12 -4 10 -21 34 -37 51 -27 29 -29 35 -19 58 24 51 9
121 -33 157 -38 33 -113 48 -240 48 -81 0 -125 -4 -133 -12 -17 -17 -15 -67 3
-74 21 -8 23 -344 2 -344 -14 0 -25 18 -62 110 -101 246 -127 305 -138 312
-14 9 -128 11 -151 2 -18 -7 -22 -64 -6 -80 9 -9 -15 -83 -88 -268 -45 -115
-52 -101 -52 93 0 143 2 173 15 177 21 8 20 70 -1 78 -9 3 -44 6 -79 6 -48 0
-64 -4 -69 -16 -10 -26 -7 -51 9 -64 10 -9 15 -32 15 -76 0 -35 -3 -64 -7 -64
-5 0 -46 50 -93 110 l-85 109 -80 1 c-50 0 -86 -5 -93 -12z m3856 -30 c36 -14
45 -35 40 -89 -4 -49 -30 -49 -52 1 -23 52 -58 77 -93 64 -60 -21 -52 -76 17
-121 148 -95 170 -125 150 -199 -15 -53 -65 -100 -116 -110 -49 -9 -154 2
-198 20 -36 15 -36 16 -36 75 0 75 20 82 50 18 23 -50 63 -77 113 -77 41 0 60
19 55 57 -2 22 -17 37 -67 70 -84 55 -118 89 -132 131 -34 103 50 188 176 178
33 -3 75 -11 93 -18z m-3588 -122 c58 -74 108 -135 113 -135 4 -1 7 48 7 108
0 84 -3 111 -15 121 -31 26 -17 40 40 40 59 0 72 -15 40 -46 -12 -13 -15 -51
-15 -215 l0 -199 -44 0 c-37 0 -47 5 -67 32 -26 34 -70 90 -164 207 l-60 74
-3 -128 c-2 -103 0 -131 13 -145 27 -30 18 -40 -40 -40 -59 0 -67 9 -40 44 22
29 22 348 0 370 -8 8 -15 22 -15 30 0 13 13 16 73 16 l72 0 105 -134z m513 72
c119 -287 148 -351 169 -367 13 -11 21 -22 18 -24 -8 -9 -208 -12 -216 -4 -4
4 0 15 9 25 15 17 16 23 4 58 l-14 39 -73 0 -73 0 -14 -40 c-12 -34 -11 -40 2
-48 8 -4 15 -15 15 -22 0 -12 -15 -15 -65 -15 -69 0 -80 9 -45 38 19 17 32 48
108 260 31 86 35 106 24 122 -21 35 -14 40 56 40 l69 0 26 -62z m479 52 c78
-22 114 -114 67 -174 -12 -15 -20 -28 -18 -29 50 -27 67 -42 82 -71 16 -31 17
-41 7 -73 -29 -89 -64 -103 -265 -103 -134 0 -155 2 -155 16 0 8 7 22 16 31
13 13 15 42 12 190 -2 109 -7 176 -14 181 -6 4 -13 15 -17 25 -6 16 4 17 121
17 70 0 144 -5 164 -10z m426 -42 c102 -261 147 -357 174 -376 33 -24 8 -32
-98 -32 -105 0 -125 8 -88 34 14 11 14 16 -1 54 l-18 43 -70 -3 -70 -3 -14
-40 c-12 -35 -12 -41 2 -51 16 -12 20 -33 8 -35 -92 -14 -156 4 -115 33 26 18
42 52 101 213 52 144 58 169 41 180 -5 3 -10 13 -10 21 0 11 16 14 69 14 l68
0 21 -52z m535 45 c3 -5 6 -37 8 -73 5 -75 -10 -86 -39 -32 -21 39 -63 68 -86
59 -14 -6 -16 -29 -16 -175 0 -153 2 -172 20 -194 11 -15 15 -29 10 -34 -15
-15 -200 -5 -200 11 0 7 7 15 15 19 13 4 15 35 15 191 0 181 0 185 -21 185
-24 0 -79 -51 -79 -73 0 -12 -19 -27 -36 -27 -6 0 -5 51 2 104 l7 46 198 0
c109 0 200 -3 202 -7z m227 -8 c0 -9 -7 -18 -15 -21 -12 -5 -15 -32 -15 -153
0 -127 3 -150 20 -178 34 -57 116 -57 151 0 16 28 19 51 19 178 0 121 -3 148
-15 153 -8 3 -15 12 -15 21 0 12 13 15 60 15 62 0 77 -13 46 -39 -10 -9 -15
-49 -17 -164 -4 -137 -6 -156 -27 -188 -32 -52 -66 -71 -137 -77 -72 -5 -122
8 -167 46 -50 42 -58 75 -58 230 0 112 -3 142 -15 152 -8 7 -15 19 -15 26 0
11 22 14 100 14 83 0 100 -3 100 -15z m480 0 c0 -9 -7 -18 -15 -21 -13 -5 -15
-36 -15 -190 l0 -184 54 0 c60 0 100 23 110 65 4 16 13 25 27 25 19 0 20 -4
13 -62 -3 -35 -10 -67 -14 -71 -10 -11 -352 -9 -363 2 -4 4 2 16 13 26 19 17
20 30 20 195 0 146 -3 180 -15 190 -8 7 -15 19 -15 26 0 11 22 14 100 14 83 0
100 -3 100 -15z m380 0 c0 -9 -7 -18 -15 -21 -12 -5 -15 -32 -15 -153 0 -127
3 -150 20 -178 35 -59 126 -55 157 7 20 39 18 304 -2 320 -32 27 -17 40 45 40
46 0 60 -3 60 -15 0 -7 -6 -18 -14 -22 -11 -6 -15 -42 -18 -158 -3 -155 -13
-193 -57 -236 -63 -60 -224 -48 -288 22 -36 40 -43 74 -43 218 0 125 -2 140
-20 156 -11 10 -17 22 -14 26 3 5 50 9 105 9 82 0 99 -3 99 -15z m830 7 c0 -5
-9 -17 -20 -27 -18 -16 -20 -31 -20 -149 0 -105 4 -138 18 -166 23 -46 58 -65
104 -56 30 5 40 14 57 49 18 37 21 62 21 175 0 109 -3 134 -15 138 -8 4 -15
15 -15 25 0 16 8 19 53 19 64 0 74 -7 48 -34 -17 -19 -20 -39 -23 -178 -3
-170 -8 -184 -70 -231 -59 -44 -203 -29 -262 27 -45 42 -56 88 -56 242 0 108
-3 133 -15 138 -8 3 -15 12 -15 21 0 12 17 15 105 15 58 0 105 -4 105 -8z
m561 -3 c75 -25 104 -84 75 -152 -8 -21 -20 -37 -25 -37 -6 0 -11 -4 -11 -10
0 -5 7 -10 15 -10 8 0 30 -16 50 -35 41 -41 47 -87 18 -137 -34 -57 -73 -68
-253 -68 -137 0 -160 2 -160 15 0 9 7 18 15 21 13 5 15 36 15 189 0 150 -3
185 -15 195 -8 7 -15 19 -15 26 0 11 26 14 129 14 70 0 143 -5 162 -11z m-898
-63 c9 -7 22 -29 29 -47 13 -39 24 -49 55 -49 27 0 35 13 44 65 5 30 7 18 8
-47 0 -49 -3 -88 -7 -88 -5 0 -17 9 -27 20 -10 11 -41 34 -69 52 -69 43 -96
68 -96 90 0 22 36 24 63 4z m-218 -146 c8 -16 52 -55 96 -86 44 -31 83 -63 86
-71 8 -21 -17 -34 -52 -27 -40 8 -50 17 -74 69 -16 34 -28 46 -53 53 -26 6
-34 14 -36 36 -3 28 3 56 12 56 3 0 12 -13 21 -30z"/>
<path d="M1996 3318 c-9 -29 -20 -59 -23 -65 -3 -9 8 -13 41 -13 53 0 55 6 29
72 -24 63 -28 63 -47 6z"/>
<path d="M2489 3468 c-6 -22 -1 -138 6 -139 66 -8 103 75 53 121 -18 16 -56
28 -59 18z m51 -74 c0 -24 -5 -34 -15 -34 -10 0 -15 10 -15 33 0 19 3 37 6 40
13 13 24 -5 24 -39z"/>
<path d="M2484 3190 l1 -80 33 0 c45 0 72 30 72 80 0 48 -30 80 -76 80 l-31 0
1 -80z m74 38 c7 -7 12 -24 12 -38 0 -30 -17 -50 -42 -50 -15 0 -18 8 -18 43
0 24 3 47 7 50 10 11 28 8 41 -5z"/>
<path d="M2901 3309 c-11 -29 -21 -56 -21 -60 0 -5 21 -9 46 -9 l46 0 -7 33
c-4 17 -11 36 -16 42 -5 5 -9 17 -9 27 0 33 -17 18 -39 -33z"/>
<path d="M5920 3396 c0 -42 2 -76 5 -76 3 0 21 7 40 14 25 11 35 22 37 38 4
59 -10 82 -56 94 l-26 6 0 -76z m58 -8 c-5 -37 -28 -33 -28 5 0 18 3 37 7 41
10 10 25 -21 21 -46z"/>
<path d="M5920 3190 l0 -80 30 0 c41 0 80 39 80 80 0 41 -39 80 -80 80 l-30 0
0 -80z m66 42 c32 -22 20 -92 -16 -92 -17 0 -20 6 -20 50 0 49 9 60 36 42z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
